import { Select } from 'antd';
import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getFilter } from '../../api';
import Flag from '../../components/flag/index.jsx';
import HorizontalLine from './horizontal.jsx';
import {
  PackageAiItem as Item,
  PackageAiProduct as ProductItem,
} from './item.jsx';
import style from './tree.module.scss';

function useRowShowHook({ show, scrollToRow }) {
  const [display, setDisplay] = useState('none');
  const [opacity, setOpacity] = useState(0);
  const onShowChange = useCallback(
    (ss) => {
      if (ss) {
        setDisplay('flex');
        setTimeout(() => {
          scrollToRow && scrollToRow();
          setOpacity(1);
        }, 10);
      } else {
        setOpacity(0);
        setTimeout(() => {
          setDisplay('none');
        }, 300);
      }
    },
    [setDisplay, setOpacity, scrollToRow]
  );
  useEffect(() => {
    onShowChange(show);
  }, [show]);
  const styleMap = useMemo(() => {
    return {
      display,
      opacity,
    };
  }, [display, opacity]);

  return { styleMap };
}

function getIndexPosition(index, length) {
  if (!length) return 0;

  const l = (length - 1) / 2;
  return index - l;
}

function getIndexPositionProduct(index) {
  return index === 0 ? 0 : 1;
}

function Product({ className, item, indexPosition = 0 }) {
  return (
    <ProductItem
      className={className}
      title={item.title}
      image={item.cover}
      brand={item.brand}
      appeal={item.appeal}
      upi={item.upi}
      indexPosition={indexPosition}
    />
  );
}

function ProductColumn({ className, show = false, items }) {
  const columnRef = useRef();
  const scrollToColumn = useCallback(() => {
    if (!columnRef.current) return;
    columnRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [columnRef.current]);

  const { styleMap } = useRowShowHook({ show, scrollToRow: scrollToColumn });

  return (
    <div
      ref={columnRef}
      className={classNames(style.productColumn, className)}
      style={styleMap}
    >
      {items.map((item, index) => (
        <Product
          key={item.id}
          item={item}
          indexPosition={getIndexPositionProduct(index)}
        />
      ))}
    </div>
  );
}

function Category({
  className,
  market,
  data,
  item,
  indexPosition = 0,
  onlyOne,
}) {
  const [showChildren, setShowChildren] = useState(false);

  const productDataList = useMemo(() => {
    return data
      .filter((e) => {
        return e.market === market && e.category === item.title;
      })
      .map((e) => {
        return {
          id: `product-${e.sn}`,
          level: 'product',
          title: e.display_name,
          cover: e.cover,
          brand: e.brand_logo_visibility,
          appeal: e.appeal,
          upi: e.upi,
        };
      });
  }, [data, market, item]);

  return (
    <div className={style.categoryItemBox}>
      <HorizontalLine pos={indexPosition} showLeftChildren={showChildren} />
      <div className={classNames(style.categoryItem, className)}>
        <Item
          title={item.title}
          brand={item.brand}
          appeal={item.appeal}
          upi={item.upi}
          hasParent={true}
          hasChildren={productDataList.length > 0}
          showChildren={showChildren}
          setShowChildren={setShowChildren}
          leftChildren={true}
          onlyOne={onlyOne}
        />
        <ProductColumn show={showChildren} items={productDataList} />
      </div>
    </div>
  );
}

function CategoryRow({ className, show = false, market, data, items }) {
  const rowRef = useRef();
  const scrollToRow = useCallback(() => {
    if (!rowRef.current) return;
    rowRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [rowRef.current]);

  const { styleMap } = useRowShowHook({ show, scrollToRow });

  const onlyOne = useMemo(() => items.length === 1, [items]);

  return (
    <div
      ref={rowRef}
      className={classNames(style.categoryRow, className)}
      style={styleMap}
    >
      {items.map((item, index) => (
        <Category
          key={item.id}
          data={data}
          market={market}
          item={item}
          indexPosition={getIndexPosition(index, items.length)}
          onlyOne={onlyOne}
        />
      ))}
    </div>
  );
}

function Market({ className, data, item, indexPosition = 0 }) {
  const categoryDataList = useMemo(() => {
    // build category
    const mm = new Map();
    for (const d of data) {
      if (d.market !== item.title) continue;
      if (mm.has(d.category)) {
        const m = mm.get(d.category);
        m.brand += d.brand_logo_visibility;
        m.appeal += d.appeal;
        m.upi += d.upi;
        m.itemCount += 1;
      } else {
        mm.set(d.category, {
          id: `category-${d.category}`,
          level: 'category',
          title: d.category,
          brand: d.brand_logo_visibility,
          appeal: d.appeal,
          upi: d.upi,
          itemCount: 1,
        });
      }
    }

    const ml = Array.from(mm.values());
    for (const m of ml) {
      if (m.itemCount) {
        m.brand /= m.itemCount;
        m.appeal /= m.itemCount;
        m.upi /= m.itemCount;
      }
    }

    return ml;
  }, [data, item]);

  const [showChildren, setShowChildren] = useState(false);
  useEffect(() => {
    setShowChildren(false);
  }, [data, item]);

  return (
    <div className={style.marketItemBox}>
      <HorizontalLine pos={indexPosition} />
      <div className={classNames(style.marketItem, className)}>
        <Item
          title={
            <div className={style.marketHeader}>
              <Flag className={style.marketFlag} country={item.title} />
              <div>{item.title}</div>
            </div>
          }
          brand={item.brand}
          appeal={item.appeal}
          upi={item.upi}
          hasParent={true}
          hasChildren={categoryDataList.length > 0}
          showChildren={showChildren}
          setShowChildren={setShowChildren}
        />
        <CategoryRow
          show={showChildren}
          market={item.title}
          data={data}
          items={categoryDataList}
        />
      </div>
    </div>
  );
}

function MarketRow({ className, show = false, data, items }) {
  const rowRef = useRef();
  const scrollToRow = useCallback(() => {
    if (!rowRef.current) return;
    rowRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [rowRef.current]);

  const { styleMap } = useRowShowHook({ show, scrollToRow });

  const onlyOne = useMemo(() => items.length === 1, [items]);

  return (
    <div
      ref={rowRef}
      className={classNames(style.marketRow, className)}
      style={styleMap}
    >
      {items.map((item, index) => (
        <Market
          key={item.id}
          data={data}
          item={item}
          indexPosition={getIndexPosition(index, items.length)}
          onlyOne={onlyOne}
        />
      ))}
    </div>
  );
}

function Root({ className, brand, setBrand, data, item }) {
  const [brandOptions, setBrandOptions] = useState([]);
  useEffect(() => {
    const f = async () => {
      const { data } = await getFilter('brand');
      if (data) setBrandOptions(data.map((d) => ({ label: d, value: d })));
    };
    f().catch((err) => {
      console.error('get brand options error', err);
    });
  }, [setBrandOptions]);

  const [showChildren, setShowChildren] = useState(false);
  useEffect(() => {
    setShowChildren(false);
  }, [brand, data, item]);

  const marketDataList = useMemo(() => {
    // build market
    const mm = new Map();
    for (const d of data) {
      if (mm.has(d.market)) {
        const m = mm.get(d.market);
        m.brand += d.brand_logo_visibility;
        m.appeal += d.appeal;
        m.upi += d.upi;
        m.itemCount += 1;
      } else {
        mm.set(d.market, {
          id: `market-${d.market}`,
          level: 'market',
          title: d.market,
          brand: d.brand_logo_visibility,
          appeal: d.appeal,
          upi: d.upi,
          itemCount: 1,
        });
      }
    }

    const ml = Array.from(mm.values());
    for (const m of ml) {
      if (m.itemCount) {
        m.brand /= m.itemCount;
        m.appeal /= m.itemCount;
        m.upi /= m.itemCount;
      }
    }

    return ml;
  }, [data]);

  return (
    <div className={style.rootItem}>
      <Item
        className={classNames(style.root, className)}
        title={
          <Select
            className={style.rootTitleSelect}
            options={brandOptions}
            value={brand}
            onChange={setBrand}
          />
        }
        brand={item.brand}
        appeal={item.appeal}
        upi={item.upi}
        hasChildren={marketDataList.length > 0}
        showChildren={showChildren}
        setShowChildren={setShowChildren}
      />
      <MarketRow show={showChildren} data={data} items={marketDataList} />
    </div>
  );
}

function PackageAiTree({ data, zoom = 1, brand = '', setBrand }) {
  const rootData = useMemo(() => {
    if (!brand || !data) return null;

    // build root
    const root = {
      id: 'root',
      level: 'brand',
      title: brand,
      brand: 0,
      appeal: 0,
      upi: 0,
      itemCount: 0,
    };
    for (const d of data) {
      root.brand += d.brand_logo_visibility;
      root.appeal += d.appeal;
      root.upi += d.upi;
      root.itemCount += 1;
    }
    if (root.itemCount) {
      root.brand /= root.itemCount;
      root.appeal /= root.itemCount;
      root.upi /= root.itemCount;
    }

    return root;
  }, [data]);

  if (!data) return null;
  return (
    <div
      className={style.treeContainer}
      style={{ transform: `scale(${zoom})` }}
    >
      <Root brand={brand} setBrand={setBrand} data={data} item={rootData} />
    </div>
  );
}

export default PackageAiTree;
