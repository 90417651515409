// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import LeftSidebarCollapsible from '../../../components/left-navigation/left-navigation.jsx';
import { App } from 'antd';
import style from './ratings-review-page.module.scss';
import HeaderNav from '../components/header-nav/header-nav.jsx';
import BorderContainer from '../components/border-container/border-container.jsx';
// eslint-disable-next-line no-unused-vars
import classNames from 'classnames';
import FilterTool from '../components/filter-tool/filter-tool.jsx';
import MainCom from '../components/main-com/main-com.jsx';
import AdvanceCom from '../components/advance-com/advance-com.jsx';

let message;

const RatingsReviewPage = () => {
  const staticFunction = App.useApp();
  message = staticFunction.message;

  const [currentTab, setCurrentTab] = useState('main');
  const setCurrentTabFn = (tab) => {
    setCurrentTab(tab);
  };

  // const [searchParams, setSearchParams] = useState({
  //   start_at: '202001',
  //   end_at: '202412',
  //   market: ['ID'],
  //   retailer: ['tokopedia.com'],
  //   brand: ['Ajinomoto', 'Knorr']

  //   // product_id: ['AF8FB422-E843-BA28-6892-6E4A60AF97CE', '644B1851-A525-59D6-20AD-06CF965152D4'],
  // });
  
  const [searchParams, setSearchParams] = useState(null);

  /******** ????phyPackage ********/
  // const putParams = ({start_at, end_at, product_id, brand, market, retailer, phyPackage}) => {
  const putParams = ({start_at, end_at, product_id, brand, market, retailer, format }) => {
    if (start_at && end_at && (product_id.length || brand.length)) {
      start_at = start_at?.replace('-', '')??start_at;
      end_at = end_at?.replace('-', '')??end_at;
      console.log(start_at, end_at, product_id, brand);
      if (product_id.length) {
        setSearchParams({
          start_at,
          end_at,
          market,
          retailer,
          product_id,
          format
        });
      } else if (brand.length) {
        setSearchParams({
          start_at,
          end_at,
          market,
          retailer,
          brand,
          /******** ????phyPackage ********/
          // package: phyPackage
          format
        });
      }
      
    } else {
      setSearchParams(null);
    }
  };

  const headerTitle = (
    <div className={style.tabBoxContainer}>
      <div className={classNames(style.tabItem, currentTab === 'main' ? style.active : '')} onClick={() => setCurrentTabFn('main')}>Main</div>
      <div className={classNames(style.tabItem, currentTab === 'advanced' ? style.active : '')} onClick={() => setCurrentTabFn('advanced')}>Advanced</div>
    </div>
  );

  return (
    <>
      <LeftSidebarCollapsible />
      <div className={style.fixedContent}>
        <HeaderNav />
      </div>
      <div className={style.pageContainer}>
        <BorderContainer headerTitle={headerTitle}>
          {currentTab === 'main' && <div className={style.pageBox}>
            <div className={style.filterBox}>
              <FilterTool putParams={putParams} />
            </div>
            <div className={style.pageMainBox}>
              <MainCom searchParams={searchParams} />
            </div>
          </div>}
          {currentTab === 'advanced' && <div>
            <AdvanceCom searchParams={searchParams} />
          </div>}
        </BorderContainer>
      </div>
    </>
  );
};

export default RatingsReviewPage;

export { message };