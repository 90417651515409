import React, { useMemo } from 'react';
import classNames from 'classnames';
import style from './horizontal.module.scss';

// 奇数 1 0 -1
// 偶数 1 0.5 0 -0.5 -1
function HorizontalLine({ className, pos = 0, showLeftChildren = false }) {
  const posClass = useMemo(() => {
    if (pos >= 1) {
      return style.right;
    } else if (pos > 0) {
      return style.right2;
    } else if (pos <= -1) {
      return style.left;
    } else if (pos < 0) {
      return style.left2;
    } else if (pos === 0) {
      return style.middle;
    } else {
      console.warn('unkown pos', pos);
    }
  }, [pos]);

  const showLeftChildrenClass = useMemo(() => {
    if (showLeftChildren) {
      return style.showLeftChildren;
    }
  }, [showLeftChildren]);

  return (
    <div
      className={classNames(
        style.horizontalLine,
        posClass,
        showLeftChildrenClass,
        className
      )}
    />
  );
}

export default HorizontalLine;
